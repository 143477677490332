import { useLogoutUser } from 'actions/userActions';
import { RoutePaths } from "App/routing";
import classNames from "classnames";
import { Button, ButtonStyle } from "components/Buttons";
import { LoginInputCard } from "components/Common/SingleInputCard";
import { QuestionButtonIcon } from "components/Icons";
import { WhoIsVestaModal } from "components/Modals/WhoIsVestaModal";
import Footer from "Layout/Footer";
import Header from "Layout/Header";
import { vestaExternalLinks } from "lib/config";
import { ResourceLayout } from "pages/Resources";
import { useEffect, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { HomeCard, HomeCardType } from "./HomeCard";
import styles from './styles.module.scss';

export const HomeKingston = () => {

  const logoutUser = useLogoutUser();

  const [search211Term, setSearch211Term] = useState("");
  const [distance, setDistance] = useState<string>("50");
  const [whoIsVestaModalIsOpen, setWhoIsVestaModalIsOpen] = useState(false);

  const openWhoIsVestaModal = () => {
    setWhoIsVestaModalIsOpen(true);
  }

  useEffect(()=>{
    logoutUser();
  }, []);

  return (
    <div className={classNames(styles.homePage, 'homePageAnim')}>
      <div className={styles.homeContent}>
        <Header showBackButton={false}/>

        <Button onClick={openWhoIsVestaModal} style={ButtonStyle.None} className={styles.whoIsVestaLink}>
          <span className={styles.whoIsVestText}><FormattedMessage id="home.whoIsVesta" defaultMessage="Who is Vesta?"/></span>
          <QuestionButtonIcon className={styles.questionIcon}/>
        </Button>

        <h1 className={styles.homeHeader}><FormattedMessage id="home.header.whereStart" /></h1>

        <div className={styles.cardsContainer}>
          <div className={styles.leftCards}>
            <div className={styles.leftHomeCards}>
              <HomeCard title={<FormattedHTMLMessage id="home.report.title" defaultMessage="Report or Record"/>}
                subtitle={<FormattedHTMLMessage id="home.report.subtitle" defaultMessage="Share your story, and choose who can see it."/>}
                page={RoutePaths.reportStart}
                cardType={HomeCardType.Report}
                className={styles.reportCard}/>
            </div>

            <LoginInputCard/> 
          </div>

          <div className={styles.rightCards}>
            <div className={styles.rightCardsContent}>
              <div className={styles.rightHomeCards}>
                <HomeCard title={<FormattedMessage id="home.resources.title" defaultMessage="Vesta Resources Library"/>} 
                  subtitle={<FormattedMessage id="home.resources.subtitle" defaultMessage="View resources on mental and emotional health, legal aid, and other relevant topics."/>}
                  page={vestaExternalLinks.resources} 
                  cardType={HomeCardType.Resources}
                  className={styles.resourcesCard}/>

                <HomeCard title={<FormattedMessage id="home.feedback.title" defaultMessage="Feedback"/>} 
                  subtitle={<FormattedMessage id="home.feedback.subtitle" defaultMessage="Let us know what we can do to improve"/>}
                  page={RoutePaths.feedback} 
                  cardType={HomeCardType.Feedback}
                  className={styles.feedbackCard}/>
              </div>

              <ResourceLayout 
                search211Term={search211Term}
                setSearch211Term={setSearch211Term}
                distance={distance}
                setDistance={setDistance}
                useHomeView={true}
              />
            </div>

            <div className={styles.borderBlue}></div>
          </div>
        </div>
      </div>
      <Footer/>
      <WhoIsVestaModal whoIsVestaModalIsOpen={whoIsVestaModalIsOpen}
        setWhoIsVestaModalIsOpen={setWhoIsVestaModalIsOpen}/>
    </div>
  );
}
