import { BaseModal } from "components/Modals/BaseModal";
import { LocationSelect } from "pages/ReportStart/LocationSelect";
import { Button } from "components/Buttons";
import stlyes from './styles.module.scss'
import { FormattedMessage } from "react-intl";

interface LocationSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
} 
export const LocationSelectionModal = ({isOpen, onClose}: LocationSelectionModalProps) => {


  return (
    <BaseModal isOpen={isOpen} 
      onClose={onClose}
      showCloseButton={true} >
      <div className={stlyes.container}>
        <h3 className={stlyes.title}>{<FormattedMessage id={'selectLocationModal.title'}/>}</h3>
        <LocationSelect />
        <Button onClick={onClose}>{<FormattedMessage id={'selectLocationModal.button.done'}/>}</Button>
      </div>
    </BaseModal>
  );
}
