import React, { FC } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import { Home } from 'pages/Home';
import { HomeKingston } from 'pages/HomeKingston';
import { Report } from 'pages/Report';
import { ReportStart } from 'pages/ReportStart';
import { PersonalInfo } from 'pages/PersonalInfo';
import { ResourceLocations } from 'pages/ResourceLocations';
import { Feedback } from 'pages/Feedback';
import { ReviewReport } from "pages/ReviewReport";
import { UpdateSubmittedReport } from "pages/UpdateSubmittedReport";
import { ReportPath } from "pages/ReportPath";
import { ReportSubmitSuccess } from "pages/ReportSubmitSuccess";
import { ReportPrint } from 'pages/ReportPrint';
import { Survey } from "pages/Survey";
import { ClickthroughPage } from "pages/Home/ClickthroughPage";
import { useClassicKingston } from "lib/config";

export const RoutePaths = {
  report: '/report',
  reportStart: '/reportStart',
  reportPath: '/reportPath',
  reportPrint: '/reportPrint',
  personalInfo: '/personalInfo',
  reviewReport: '/reviewReport',
  updateSubmittedReport: '/updateSubmittedReport',
  resources: '/resources',
  resourceLocations: '/resourceLocations',
  feedback: '/feedback',
  index: '/',
  landing: '/landing',
  kingstonLanding: '/kingstonLanding',
  reportSubmitSuccess: '/reportSubmitSuccess',
  survey: '/survey',
  clickthrough: '/clickthrough',
};


export const Routing : FC = () => {
  return (
    <Router>
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              <Route path={RoutePaths.report}>
                <Report />
              </Route>
              <Route path={RoutePaths.reportStart}>
                <ReportStart />
              </Route>
              <Route path={RoutePaths.reportPath}>
                <ReportPath/>
              </Route>
              <Route path={RoutePaths.reportPrint}>
                <ReportPrint />
              </Route> 
              <Route path={RoutePaths.personalInfo}>
                <PersonalInfo />
              </Route>
              <Route path={RoutePaths.reviewReport}>
                <ReviewReport/>
              </Route>
              <Route path={RoutePaths.updateSubmittedReport}>
                <UpdateSubmittedReport/>
              </Route>
              <Route path={RoutePaths.resources}>
                <ResourceLocations/>
              </Route>
              <Route path={RoutePaths.resourceLocations}>
                <ResourceLocations/>
              </Route>
              <Route path={RoutePaths.feedback}>
                <Feedback/>
              </Route>
              <Route path={RoutePaths.reportSubmitSuccess}>
                <ReportSubmitSuccess />
              </Route>
              <Route path={RoutePaths.survey}>
                <Survey />
              </Route>
              <Route path={RoutePaths.clickthrough}>
                <ClickthroughPage />
              </Route>
              <Route path={RoutePaths.landing}>
                <Home/>
              </Route>
              <Route path={RoutePaths.kingstonLanding}>
                <HomeKingston/>
              </Route>
              <Route path={RoutePaths.index}>
                {useClassicKingston ? <HomeKingston /> : <Home/>}
              </Route>
            </Switch>
          );
        }}
      />
    </Router>
  );
};
