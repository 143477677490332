import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from './styles.module.scss';
import { ArrowRightDark } from "components/Icons";
import { ReportHomeIcon, FeedbackHomeIcon, ResourcesHomeIcon } from "components/Icons";

export interface HomeCardProps {
  className: string;
  title: ReactNode;
  subtitle: ReactNode;
  page: string;
  cardType?: HomeCardType;
  isExternal?: boolean;
}

export enum HomeCardType {
  Report='report',
  Resources='resources',
  Feedback='feedback'
}

export const HomeCard = ({title, subtitle, page, className, cardType, isExternal = false}: HomeCardProps) => {

  let cardTypeStyle = null;
  switch (cardType) {
    case HomeCardType.Report:
      cardTypeStyle = <ReportHomeIcon />;
      break;
    case HomeCardType.Resources:
      cardTypeStyle = <ResourcesHomeIcon />;
      break;
    case HomeCardType.Feedback:
      cardTypeStyle = <FeedbackHomeIcon />;
      break;
    default:
      break;
  }

  return (
    <>
    { isExternal ? 
      (
        <Link to={page} className={className}>
        <div className={classNames(styles.cardHome, styles && styles[page])}>
          <div className={styles.cardIcon}>{cardTypeStyle}</div>

          <div className={styles.cardContent}>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>

          <div className={styles.arrowRight}><ArrowRightDark/></div>
        </div>
      </Link>
      ) : 
      (
        <a href={page} className={className}>
          <div className={classNames(styles.cardHome, styles && styles[page])}>
            <div className={styles.cardIcon}>{cardTypeStyle}</div>

            <div className={styles.cardContent}>
              <h2>{title}</h2>
              <p>{subtitle}</p>
            </div>

            <div className={styles.arrowRight}><ArrowRightDark/></div>
          </div>
        </a>
      )}

    </>
  );
}
