import { RoutePaths } from "App/routing";
import classNames from "classnames";
import { ArrowLink, ButtonStyle, LinkButton } from "components/Buttons/Button";
import { ResourcesNearMe } from "components/Common/ResourcesNearMe";
import { TextField, TextFieldTypes } from "components/Forms/TextField";
import { RadioChoice } from "components/Forms/Toggle/RadioChoice";
import { Ontario211Icon } from "components/Icons";
import { vestaExternalLinks } from "lib/config";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { matchPath, useLocation } from "react-router-dom";
import styles from './styles.module.scss';


interface ResourceLayoutProps {
  children?: ReactNode;
  search211Term: string;
  setSearch211Term: (search211Term: string) => void;
  distance: string;
  setDistance: (distance: string) => void;
  useHomeView?: boolean;
}

export const ResourceLayout = ({ 
  children, 
  search211Term,
  setSearch211Term,
  distance,
  setDistance,
  useHomeView,
}: ResourceLayoutProps) => {
  const intl = useIntl();
  const location: any = useLocation();
  
  return (
    <div className={styles.resourcePage}>
      <div className={classNames(!useHomeView && styles.resourcesCenterPage)}>
        <div className={classNames(
          useHomeView ? 
          styles.homeResourcesCard :
          styles.frozenRow
          )}>
          <div className={styles.frozenRowContent}>
            {!useHomeView && (
              <>
                <LinkButton to={RoutePaths.index} style={ButtonStyle.None} className={styles.header}>
                  <div className={classNames(styles.headerImage, styles.vestaLogo)}/>
                </LinkButton>
                
                <ArrowLink className={styles.backButton} to={RoutePaths.index}>
                  <FormattedMessage id="common.backButton" defaultMessage="Go back"/>
                </ArrowLink>
              </>
            )}

            <div className={styles.inputOptions}>
              <div className={styles.attributionContainer}>
                <div className={styles.attributionIcon}>
                  <Ontario211Icon/>
                </div>
                <a className={styles.attribution} href={vestaExternalLinks.twoOneOneOntario}>
                  Data powered by 211ontario
                </a>
              </div>

              <TextField 
                type={TextFieldTypes.Text} 
                placeholder={intl.formatMessage({ id: "search211Term.placeholder", defaultMessage: "Enter a topic or organization" })} 
                name="search211Term"  
                onChange={value => { setSearch211Term(value); }}
                value={search211Term}
                className={styles.search211Term} 
              />

              <div className={styles.resourcesInputContainer}>
                <ResourcesNearMe 
                  className={styles.searchInput} 
                  defaultAddress={location.state?.address}
                  search211Term={search211Term}
                  distance={distance}
                /> 
                <RadioChoice    
                  groupName="distanceOptions"
                  value={distance}
                  onChange={value => { setDistance(value); }}
                  options={["10","25","50","100"].map(n => ({ value: n, text: n }))}
                  description={"Max Distance (km)"}
                  className={styles.distanceOptions}
                />          
              </div>
            </div>
          </div>
        </div>

        {!useHomeView && (
          <div className={styles.resourcesListContainer}>
            <div className={styles.resourcesList}>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
)};
